import React from 'react';
import {Signature} from '../components/Icons';

const ColumnTop = () => {
  const ColumnTop1 = () => {
    return (
      <div>
        <h2>Explore</h2>
        <ul>
          <li>Coming soon...</li>
        </ul>
      </div>
    );
  }

  const ColumnTop2 = () => {
    return (
      <div>
        <h2>Connect</h2>
        <ul>
          <li><a href="https://www.linkedin.com/in/patriciagoh/" target="_blank">LinkedIn</a></li>
          <li><a href="http://instagram.com/bypatriciagoh/" target="_blank">Instagram</a></li>
        </ul>
      </div>
    );
  }

  const ColumnTop3 = () => {
    return (
      <div>
        <h2>Collaboration</h2>
        <ul>
          <li><a href="https://calendly.com/patriciagoh" target="_blank">Calendly</a></li>
          <li><a href="https://github.com/patriciagoh" target="_blank">GitHub</a></li>
        </ul>
      </div>
    );
  }

  const ColumnTop4 = () => {
    return (
      <div>
        <h2>Email</h2>
        <a href="mailto:bypatriciagoh@gmail.com">bypatriciagoh@gmail.com</a>
      </div>
    );
  }

  return (
    <div>
      <ColumnTop2 />
      <ColumnTop3 />
      <ColumnTop1 />
      <ColumnTop4 />
    </div>
  );
}

const ColumnBottom = () => {
  return (
    <div className="column-bottom">
      <h4>Last seen in Vancouver</h4>
      <div>
        <h5>Powered by hot chocolate</h5>
        <h5>© 2024 patriciagoh.com</h5>
      </div>
    </div>
  );
}

const Footer = () => {
  return (
    <footer>
      <ColumnTop />
      <ColumnBottom />
      <Signature />
    </footer>
  );
}

export default Footer;
